import React from "react"
import * as style from "./style.module.scss"

function UpdateButton() {
  const updateHandler = () => {
    window.location.reload()
  }

  return (
    <div className={`d-none ${style.updater}`} id="updater">
      <span className="me-3 d-none d-sm-inline-block">New Version Available</span>
      <button
        className="ant-btn ray__btn ray__btn--small ray__btn--white ray__btn--round"
        onClick={updateHandler}
      >
        <i className="ri ri-reload me-2" />
        <strong>Update App</strong>
      </button>
    </div>
  )
}

export default UpdateButton
