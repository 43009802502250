import React from "react"
import { SVGCardano } from "@/svg"
import * as style from "./style.module.scss"

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className="ray__block mb-0">
        <p className="mb-1 text-muted">
          {new Date().getFullYear()} &copy;{" "}
          <a href="https://ray.foundation" target="_blank" rel="noopener noreferrer" className="text-muted"><u>Ray Foundation DAO</u></a>{" "}
          · Advanced Ecosystem for Cardano Blockchain Platform ·{" "}
          <span>
            <span>Powered with</span>{" "}
            <a
              href="https://cardano.org/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className={style.footerCardano}>
                <SVGCardano />
              </span>
              <strong>Cardano</strong>
            </a>
          </span>
        </p>
      </div>
    </div>
  )
}

export default Footer
