import { Tooltip } from "antd"
import React, { useState, useEffect } from "react"
import { useSelector } from 'react-redux'
import { format } from '@/utils'
import * as style from "./style.module.scss"

const Sidebar = () => {
  const networkState = useSelector((state) => state.settings.networkState)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    setAnimate(true)
    const int = setInterval(() => {
      setAnimate(false)
    }, 700)
    return () => {
      clearInterval(int)
    }
  }, [networkState.tip])

  return (
    <div className={style.sidebar}>
      <div>
        <Tooltip title="by Ray Network" placement="right">
          <img className={style.logo} src="/resources/icons/ray.svg" alt="RayData" />
        </Tooltip>
      </div>
      <Tooltip title="Online" placement="right">
        <div className={style.sidebarBottom}>
          <div>
            <span className={`${style.statusDot} ${style.statusDotGreen} ${animate ? style.statusDotAnimate : ''}`} />
          </div>
          <div className="text-nowrap">
            <div>Block: {format(networkState?.tip?.number || 0)}</div>
            <div>Epoch: {networkState?.currentEpoch?.number || 0}</div>
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

export default Sidebar
